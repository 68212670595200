import React from 'react'
import AboutHero from './AboutHero/AboutHero'
import Mission from './Mvv/Mvv'

const About = () => {
  return (
    <>
    <AboutHero/>
    <Mission/>
    </>
  )
}

export default About