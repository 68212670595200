import React from 'react'
import styles from './Contact.module.css'
import '@fortawesome/fontawesome-free/css/all.min.css';


const Contact = () => {
  return (
    <>
      <div className={styles.contact_page}>
        <div className={styles.contact_page_hero}>

        </div>

        <div className={styles.contact_header}>
          <div className={styles.contact_header_icons}>
            <div className={styles.icon_holder}>
              <i class="fa-solid fa-location-dot"></i>
              <p>Address:- Deccan, Pune -Maharashtra</p>
            </div>

            <div className={styles.icon_holder}>
              <i class="fa-solid fa-phone"></i>
              <p>Phone:- +91 99229 66739</p>
            </div>

            <div className={styles.icon_holder}>
              <i class="fa-solid fa-envelope"></i>
              <p>Email:- info@yeskondomedia.com</p>
            </div>

          </div>
        </div>
        <div className={styles.contact_form_main}>
      <div className={styles.contactform_flexbox}>
        <div className={styles.contact_form}>
          <h2>Contact Us</h2>
          <form>

            <div className={styles.flexbox}> 
            <div className={styles.form_group}>
              <label>Full Name</label>
              <input type="text" placeholder="Name" />
            </div>


            <div className={styles.form_group}>
              <label>Email Address</label>
              <input type="email" placeholder="Email" />
            </div>
            </div>
           


            <div className={styles.form_group}>
              <label>Subject</label>
              <input type="text" placeholder="Subject" />
            </div>
            <div className={styles.form_group}>
              <label>Message</label>
              <textarea placeholder="Message"></textarea>
            </div>
            <button type="submit" className={styles.submit_button}>Send Message</button>
          </form>
        </div>
        <div className={styles.contact_form_image}>
          {/* <img src={contactimage} alt="Contact" /> */}
        </div>
      </div>
    </div>
      </div>


    </>
  )
}

export default Contact
